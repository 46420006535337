import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ReseptitState {
  isLoading: boolean;
  reseptiLista: Hakutulokset;
  hasMore: boolean;
  searchPhrases: string | undefined;
  laskuri: number; // used to force cludge refresh
}

export interface Kuva {
  polku: string;
  kokoX: number;
  kokoY: number;
}

export interface Hakutulokset {
  reseptiIdt: number[];
  ruokaaineIdt: number[]; // tulosten korostamista varten
  otsikkoHakusanat: string[];  // tulosten korostamista varten
  listausTiedot: ReseptiListausTiedot[];
}

export interface Ruokaaine {
  id: number;
  nimi: string;
}

export interface ReseptiListausTiedot {
  reseptiId: number;
  nimi: string;
  annosmaaraAlaraja: number;
  annosmaaraYlaraja: number;
  kuva: Kuva;
  ruokaaineet: Ruokaaine[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestReseptitAction {
    type: 'REQUEST_RESEPTIT';
}

interface ReceiveReseptitAction {
    type: 'RECEIVE_RESEPTIT';
    reseptiLista: Hakutulokset;
    hakusanat: string | undefined;
}

interface BumpAction {
  type: 'BUMP_RESEPTIT';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestReseptitAction | ReceiveReseptitAction | BumpAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  requestReseptit: (searchPhrases: string | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.reseptit && !appState.reseptit.isLoading && appState.reseptit.searchPhrases !== searchPhrases) {
      fetch(process.env.REACT_APP_API_ROOT + "/api/resepti" + (searchPhrases ? "?q=" + searchPhrases : ""))
        .then(response => response.json() as Promise<Hakutulokset>)
        .then(data => {
          dispatch({ type: "RECEIVE_RESEPTIT", reseptiLista: data, hakusanat: searchPhrases });
        }); // todo: error handling?

      dispatch({ type: "REQUEST_RESEPTIT" });
    } 
  },
  bump: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: "BUMP_RESEPTIT" })
  }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ReseptitState = { reseptiLista: { reseptiIdt: [], listausTiedot: [], otsikkoHakusanat: [], ruokaaineIdt: [] }, 
  isLoading: false, hasMore: false, searchPhrases: null, laskuri: 0 };

export const reducer: Reducer<ReseptitState> = (state: ReseptitState | undefined, incomingAction: Action): ReseptitState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
      case 'REQUEST_RESEPTIT':
        const requestState = {
          ...state,
          reseptiLista: state.reseptiLista,
          isLoading: true
        };
        return requestState;
      case 'RECEIVE_RESEPTIT':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            //if (action.startDateIndex === state.startDateIndex) {
        const receiveState = {
          ...state,
          reseptiLista: action.reseptiLista,
          searchPhrases: action.hakusanat,
          isLoading: false
        };
        return receiveState;
      case 'BUMP_RESEPTIT':
        const bumpState = {
          ...state,
          laskuri: state.laskuri + 1
        };
        return bumpState;
      default:
        return state;
    }
};
