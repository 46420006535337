import * as React from 'react';
import { connect } from 'react-redux';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import * as NavMenuStore from '../store/NavMenu';
import './NavMenu.css';
import $ from 'jquery';
import FacebookLogin from 'react-facebook-login';
 
type NavMenuProps = typeof NavMenuStore.actionCreators;

class NavMenu extends React.PureComponent<NavMenuProps, // type of props
{ isOpen: boolean, // type of state
  searchIsLoading: boolean, 
  searchOptions: Array<String>,
  searchSelections: Array<String>,
  isLoginMenuOpen: boolean,
  isLoggedIn: boolean,
  facebookLoginResponse: Object,
  firstName: String,
  pictureUrl: String
}> 
{
    constructor(props:any) {
      super(props);
      this.setSearchSelections = this.setSearchSelections.bind(this);
      this.handleSearch = this.handleSearch.bind(this);
      this.toggleLoginMenu = this.toggleLoginMenu.bind(this);
      this.responseFacebook = this.responseFacebook.bind(this);
    }

    public state = {
      isOpen: false,
      searchIsLoading: false,
      searchOptions: [],
      searchSelections: [],
      isLoginMenuOpen: false,
      isLoggedIn: false,
      facebookLoginResponse: null,
      firstName: null,
      pictureUrl: null
    };    
    
    componentDidMount() {
      $(function () {
        var lastScrollTop:number = 0;
        var $navbar = $('.navbar');
  
        $(window).scroll(function (event) { // make NavBar hidden when scrolling down and visible when scrolling up
          var st = $(this).scrollTop();
  
          if (st && st > lastScrollTop) { // scroll down
            $navbar.slideUp();
          } else { // scroll up
            $navbar.slideDown();
          }
          if (st) {
            lastScrollTop = st;
          }
        });
      });
    }

    private setSearchSelections(value:any) {
      this.setState({
        ...this.state,
        searchSelections: value
      });
    }

    private handleSearch() {
      this.props.doSearch(this.state.searchSelections);
    }

    private responseFacebook(response) {
      console.log(response);
      if (response.accessToken) {
        this.setState({
          ...this.state,
          isLoggedIn: true,
          facebookLoginResponse: response,
          firstName: response.name.split(' ')[0],
          pictureUrl: response.picture.data.url
        });
      }
    }

    private toggleLoginMenu() {
      this.setState({
        ...this.state,
        isLoginMenuOpen: !this.state.isLoginMenuOpen
      });
    }

    private renderLoginDropdown() {
      if (this.state.isLoggedIn) {
        return (
          <Dropdown isOpen={this.state.isLoginMenuOpen} toggle={this.toggleLoginMenu} className="kirjaudu-dropdown">
            <DropdownToggle caret className="logged-in-toggle">
              <div className="logged-in-info"><img src={this.state.pictureUrl} className="profile-pic"/> <span className="first-name">{this.state.firstName}</span></div>
            </DropdownToggle>
            <DropdownMenu right>
              <div onClick={this.toggleLoginMenu} className="custom-dropdown-item">Logout</div>
            </DropdownMenu>
          </Dropdown>
        );
      }
      return (
        <Dropdown isOpen={this.state.isLoginMenuOpen} toggle={this.toggleLoginMenu} className="kirjaudu-dropdown">
          <DropdownToggle caret>
            Kirjaudu
          </DropdownToggle>
          <DropdownMenu right>
            <div onClick={this.toggleLoginMenu} className="custom-dropdown-item"><FacebookLogin
              appId="178610215572785"
              autoLoad={true}
              fields="name,email,picture"
              callback={this.responseFacebook}
              cssClass="fb btn"
              icon="fa fa-facebook fa-fw"
              textButton="Kirjaudu Facebook-tunnuksilla"
            /></div>
          </DropdownMenu>
        </Dropdown>
      );
    }

    public render() {
        return (
            <header>
                <Navbar fixed='top' className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">Ruokakanta</NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2"/>
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                  <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink tag={Link} className="text-dark" to="/reseptit">Reseptit</NavLink>
                                </NavItem>
                                <Form inline className="hae-form">
                                  <AsyncTypeahead
                                    multiple
                                    id="search-typeahead"
                                    placeholder="Haku"
                                    isLoading={this.state.searchIsLoading}
                                    // labelKey={option => `${option.login}`}
                                    onSearch={(query) => {
                                      this.setState({...this.state, searchIsLoading: true});
                                      fetch(process.env.REACT_APP_API_ROOT + `/api/reseptihaku?query=${query}`)
                                        .then(resp => resp.json())
                                        .then(json => this.setState({
                                          ...this.state, 
                                          searchIsLoading: false,
                                          searchOptions: json,
                                        }));
                                    }}
                                    options={this.state.searchOptions}
                                    selected={this.state.searchSelections}
                                    onChange={this.setSearchSelections}
                                  />
                                  <Button variant="outline-success" onClick={this.handleSearch}>Hae</Button>
                                </Form>
                                { this.renderLoginDropdown() }
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            ...this.state,
            isOpen: !this.state.isOpen
        });
    }
}

export default connect(
  null, // null = no mapping of state to props
  NavMenuStore.actionCreators // Selects which action creators are merged into the component's props
)(NavMenu as any);
