import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as ReseptiStore from '../store/Resepti';
import './Resepti.css';

// At runtime, Redux will merge together...
type ReseptiProps =
  ReseptiStore.ReseptiState // ... state we've requested from the Redux store
  & typeof ReseptiStore.actionCreators // ... plus action creators we've requested
  & RouteComponentProps<{ reseptiId: string }>; // ... plus incoming routing parameters

class Resepti extends React.PureComponent<ReseptiProps> {

  // This method is called when the component is first added to the document
  public componentDidMount() {
    this.ensureDataFetched();
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {

  }

  renderResepti(resepti : ReseptiStore.ReseptinTiedot | undefined) {
    if (resepti === undefined) {
      return <h1 id="tabelLabel">Ladataan...</h1>;
    }

    return (
      <div>
        <h1 className="row col" id="tabelLabel">{resepti.nimi}</h1>
        <p className="row col">{resepti.annosmaaraAlaraja} {resepti.annosmaaraYlaraja ? `- ${resepti.annosmaaraYlaraja}` : ""} annosta</p>
        { resepti.kuvat.length > 0 &&
          <img className="row col" src={process.env.REACT_APP_BLOB_ROOT + "/kuvat/" + resepti.kuvat[0].polku} alt="reseptin kuva" />
        }
        <h2 className="row col">Ainesosat</h2>
        {resepti.rivit.map((rivi: ReseptiStore.RivinTiedot) => {
          const onValiotsikko = rivi.valiotsikko !== null;
          return (
            <div className="row" key={rivi.rivi}>
              {
                onValiotsikko
                  ? <p className="valiotsikko col-6">{rivi.valiotsikko}</p>
                  : <React.Fragment>
                      <div className="col-1">{rivi.maara}</div>
                      <div className="col-1">{rivi.yksikko}</div>
                      <div className="col">{String(rivi.ruokaaine)}</div>
                    </React.Fragment>
              }
            </div>
          );
        })}
        <h2 className="row col">Ohjeet</h2>
        <div className="row col-12 col-lg-8" dangerouslySetInnerHTML={{ __html: resepti.ohjeet }}></div>
        <div className="row">&nbsp;</div>
        <div className="row">&nbsp;</div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        { this.renderResepti(this.props.resepti) }
      </React.Fragment>
    );
  }

  private ensureDataFetched() {
    if (this.props.match.params.reseptiId) {
      const reseptiId = parseInt(this.props.match.params.reseptiId) || 0;
      if (reseptiId) {
        this.props.requestResepti(reseptiId);
      }
    } else {
      console.log("reseptiId missing");
    }
  }
}

export default connect(
  (state: ApplicationState) => state.resepti, // Selects which state properties are merged into the component's props
  ReseptiStore.actionCreators // Selects which action creators are merged into the component's props
)(Resepti as any);
