import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import packageJson from '../../package.json';

const appVersion = packageJson.version;

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <NavMenu/>
        <Container className="main-container">
            {props.children}
            <p className="version-footer">Versio {appVersion}</p>
        </Container>
    </React.Fragment>
);
