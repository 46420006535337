import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ReseptiState {
  isLoading: boolean;
  reseptiId?: number;
  resepti: ReseptinTiedot | undefined;
}

export interface Kuvantiedot {
  kuvaId: number;
  polku: string;
  laji: number;
  kokoX: number;
  kokoY: number;
  preferoitu: boolean;
}

export interface RivinTiedot {
  rivi: number;
  maara: string;
  yksikko: string;
  ruokaaine: string;
  valiotsikko: string;
}

export interface ReseptinTiedot {
  reseptiId: number;
  nimi: string;
  annosmaaraAlaraja: number;
  annosmaaraYlaraja: number;
  lisaaja: string;
  muokkaaja: string;
  lisatty: Date;
  muokattu: Date;
  ohjeet: string;
  kuvat: Kuvantiedot[];
  rivit: RivinTiedot[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestReseptiAction {
  type: 'REQUEST_RESEPTI';
  reseptiId: number;
}

interface ReceiveReseptiAction {
    type: 'RECEIVE_RESEPTI';
    resepti: ReseptinTiedot;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestReseptiAction | ReceiveReseptiAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestResepti: (reseptiId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.resepti) {
          fetch(process.env.REACT_APP_API_ROOT + "/api/Resepti/" + reseptiId)
              .then(response => response.json() as Promise<ReseptinTiedot>)
                .then(data => {
                  dispatch({ type: 'RECEIVE_RESEPTI', resepti: data });
                }
          );

          dispatch({ type: 'REQUEST_RESEPTI', reseptiId: reseptiId });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ReseptiState = { resepti: undefined, isLoading: false };

export const reducer: Reducer<ReseptiState> =
  (state: ReseptiState | undefined, incomingAction: Action): ReseptiState => {
    if (state === undefined) {
      return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
      case "REQUEST_RESEPTI":
        const requestState = {
          ...state,
          resepti: state.resepti,
          isLoading: true
        };
        return requestState;
      case "RECEIVE_RESEPTI":
        // Only accept the incoming data if it matches the most recent request. This ensures we correctly
        // handle out-of-order responses.
        //if (action.startDateIndex === state.startDateIndex) {
        const receiveState = {
          ...state,
          resepti: action.resepti,
          isLoading: false
        };

        return receiveState;
      default:
        return state;
        //}
    }
  };
