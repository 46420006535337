import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import Counter from './components/Counter';
import FetchData from './components/FetchData';
import Reseptit from './components/Reseptit';
import Resepti from './components/Resepti';

import './custom.css'

export default () => (
  <Layout>
    <Route exact path="/" component={Reseptit}/>
    <Route path="/counter" component={Counter}/>
    <Route path="/fetch-data/:startDateIndex?" component={FetchData}/>
    <Route exact path="/reseptit" component={Reseptit}/>
    <Route path="/resepti/:reseptiId" component={Resepti}/>
  </Layout>
);
