import * as WeatherForecasts from "./WeatherForecasts";
import * as Counter from "./Counter";
import * as Reseptit from "./Reseptit";
import * as Resepti from "./Resepti";

// The top-level state object
export interface ApplicationState {
  counter: Counter.CounterState | undefined;
  weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
  reseptit: Reseptit.ReseptitState | undefined;
  resepti: Resepti.ReseptiState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  counter: Counter.reducer,
  weatherForecasts: WeatherForecasts.reducer,
  reseptit: Reseptit.reducer,
  resepti: Resepti.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}