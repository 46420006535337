import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import { Link } from 'react-router-dom';
import { parse } from 'query-string';
import * as ReseptitStore from '../store/Reseptit';
import Masonry from 'react-masonry-infinite';
import './Reseptit.css';

// At runtime, Redux will merge together...
type ReseptitProps =
  ReseptitStore.ReseptitState // ... state we've requested from the Redux store
  & typeof ReseptitStore.actionCreators // ... plus action creators we've requested
  & RouteComponentProps<{ }>; // ... plus incoming routing 

class Reseptit extends React.PureComponent<ReseptitProps> {

  private masonryGrid: any;

  // This method is called when the component is first added to the document
  public componentDidMount() {
    this.ensureDataFetched();
    this.props.bump(); // forces small update of state, so we get a call in componentDidUpdate, and are able to call doForcePack
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    this.ensureDataFetched();
    this.doForcePack();
  }

  render() {
    return (
      <React.Fragment>
        <h1 id="tabelLabel">Reseptit</h1>
        { this.props.isLoading &&
          <p>Ladataan...</p>
        }
        { this.renderReseptitTable() }
        <input type="hidden" value={this.props.laskuri} />
      </React.Fragment>
    );
  }

  doForcePack() {
    this.masonryGrid.forcePack();
  }

  private ensureDataFetched() {
    const parsed:any = parse(this.props.location.search);
    this.props.requestReseptit(parsed.q);
  }

  private getImageUrl(resepti: ReseptitStore.ReseptiListausTiedot) {
    if (resepti.kuva) {
      return process.env.REACT_APP_BLOB_ROOT + "/kuvat/" + resepti.kuva.polku;
    }

    return process.env.REACT_APP_BLOB_ROOT + "/kuvat/no-image-icon.png";
  }

  private getImageWidth(resepti: ReseptitStore.ReseptiListausTiedot): number {
    if (resepti.kuva) {
      return Math.min(271, resepti.kuva.kokoX);
    }

    return 271;
  }

  private getImageHeight(resepti: ReseptitStore.ReseptiListausTiedot): number {
    if (resepti.kuva) {
      return Math.min(271, resepti.kuva.kokoY);
    }

    return 271;
  }

  private getTitleInfo(resepti: ReseptitStore.ReseptiListausTiedot) {
    if (!this.props.reseptiLista.otsikkoHakusanat)
    {
        return { title: resepti.nimi };
    }

    var otsikko: string  = resepti.nimi;
    var hakusanat = this.props.reseptiLista.otsikkoHakusanat;

    for (var i = 0; i < hakusanat.length; i++) {
      var osuma: number = otsikko.indexOf(hakusanat[i]);
      
      if (osuma >= 0) {
        return { 
          firstPart: otsikko.substring(0, osuma),
          highlighted: otsikko.substring(osuma, osuma + hakusanat[i].length),
          lastPart: otsikko.substring(osuma + hakusanat[i].length) 
        };
      }
    }

    return { title: resepti.nimi };
  }

  private loadMore() {

  }

  private hasMore(): boolean {
    return false;
  }

  private HighlightedTitle(props) {
    if (props.textInfo.highlighted) {
      return (
        <React.Fragment>{props.textInfo.firstPart}<span className="hakutuloskorostus">{props.textInfo.highlighted}</span>{props.textInfo.lastPart}</React.Fragment>
      );
    } else {
      return (
        <React.Fragment>{props.textInfo.title}</React.Fragment>
      );
    }
  }

  private renderReseptitTable() {
    return (
      <div className="row">
        <div className="col">
          <Masonry className="masonry" hasMore={this.hasMore()} loadMore={this.loadMore} ref={(child: any) => { this.masonryGrid = child; }}
                   sizes={[
                { columns: 1, gutter: 10 },
                { mq: '768px', columns: 2, gutter: 10 },
                { mq: '992px', columns: 3, gutter: 10 },
                { mq: '1200px', columns: 4, gutter: 10 }
              ]}
          >
            {this.props.reseptiLista.listausTiedot.map((resepti: ReseptitStore.ReseptiListausTiedot) =>
              <Link to={`resepti/${resepti.reseptiId}`} key={resepti.reseptiId}>
                <div className="card resepti-card">
                  <img className="card-img-top" src={this.getImageUrl(resepti)} width={this.getImageWidth(resepti)} height={this.getImageHeight(resepti)} alt="" />
                  <div className="card-body resepti-card-body">
                    <h5 className="card-title"><this.HighlightedTitle textInfo={this.getTitleInfo(resepti)} /></h5>
                    <p className="card-text">{resepti.ruokaaineet.map((ruokaaine: ReseptitStore.Ruokaaine, index) => 
                      this.props.reseptiLista.ruokaaineIdt && this.props.reseptiLista.ruokaaineIdt.some(x => x === ruokaaine.id)
                        ? <React.Fragment key={index}>{index > 0 ? ', ' : ''}<span className="hakutuloskorostus">{ruokaaine.nimi}</span></React.Fragment>
                        : <React.Fragment key={index}>{index > 0 ? ', ' : ''}{ruokaaine.nimi}</React.Fragment>
                    )}</p>
                  </div>
                </div>
              </Link>
            )}
          </Masonry>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.reseptit, // Selects which state properties are merged into the component's props
  ReseptitStore.actionCreators // Selects which action creators are merged into the component's props
)(Reseptit as any);
